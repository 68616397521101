@import url("https://fonts.googleapis.com/css?family=Roboto:500,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.wrapper {
  display: flex;
  height: 100vh;
}

.leaflet-container {
  background-color: #262625;
}

.leaflet-popup-content {
  margin: 0;
  min-width: 200px;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background-color: #121212;
}

.leaflet-popup-close-button {
  display: none;
}

.popup {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.popup h3,
.result h3 {
  color: #ffffff87;
  font-size: 20px;
  font-weight: 700;
}

.popup h3 {
  margin-bottom: 10px;
}

.popup h4,
.result h4 {
  color: #ffffff63;
  font-size: 12px;
  font-weight: 500;
}

.popup table {
  width: 100%;
}

.popup table tr td {
  color: #ffffff63;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.popup table tr td:nth-child(2) {
  color: #fff;
  font-weight: 700;
  text-align: right;
}

.search {
  position: fixed;
  z-index: 400;
  top: 0;
  right: 0;
  width: 25%;
  min-width: 300px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.search h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  margin: 15px 0;
}

.search .bar {
  background-color: #121212;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin-bottom: 15px;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' fill='%23757575'%3E%3Cpath d='M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 14px center;
  background-size: 22px;
}

.search .bar input {
  padding: 15px;
  font-size: 20px;
  color: #fff;
  background-color: transparent;
  outline: 0;
  border: none;
  width: 100%;
  padding-left: 50px;
}

.search .results {
  flex: 1;
  background-color: #121212;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.search .results .result {
  padding: 15px;
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: left;
  outline: 0;
  cursor: pointer;
}
